<template>
   <v-app id="inspire">
      <v-content class="fondo">
         <div class="contenedor">
         <v-container fluid fill-height>
            <v-layout align-center justify-center>
               <v-flex xs12 sm8 md4>
                  <v-card class="elevation-12">
                     <v-toolbar dark color="primary">
                        <v-toolbar-title class="title">DOCUMENTOS ADM</v-toolbar-title>
                     </v-toolbar>
                     <v-card-text>
                        <v-form>
                           <v-text-field
                              name="login"
                              label="EMAIL"
                              type="text"
                              v-model="usuario"
                           ></v-text-field>
                           <v-text-field
                              id="password"
                              name="password"
                              label="CONTRASEÑA"
                              type="password"
                              v-model="pass"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="comprobar">Login</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-flex>
            </v-layout>
         </v-container>
         </div>
      </v-content>
   </v-app>
</template>

<script>
const { GoogleSpreadsheet } = require('google-spreadsheet');
export default {
   name: 'Login',
   props: {
      source: String,

   },
   data: () => ({
      usuario:'',
      pass:'',
    }),
     methods: {
         async comprobar(){
            this.$notify({
                  group: 'foo',
                  title: 'COMPROBANDO CREDENCIALES',
                  text: 'Espere un momento'
                  });
            const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
            const creds = require('../../public/service_account.json'); // the file saved above

            await doc.useServiceAccountAuth(creds);
            await doc.loadInfo();

            const sheet = doc.sheetsByIndex[1];
            const rows = await sheet.getRows(); // can pass in { limit, offset }

            var jugador = "";
            var entra = 0;
            for (let i = 0; i < rows.length; i++){
               if(this.usuario==rows[i].MAIL && this.pass==rows[i].CONTRASEÑA){

                  const sheet1 = doc.sheetsByIndex[0];
                  const rows1 = await sheet1.getRows(); // can pass in { limit, offset }
                  for (let x = 0; x < rows1.length; x++){
                     if(rows[i].MAIL==rows1[x].MAIL){
                        jugador=rows1[x];
                     }
                  }
                  this.$router.push({ name: "PlayerCorner", params: {jugador: jugador}})
                  entra = 1
               }
            
            }
            if (entra == 0){
               this.$notify({
                  group: 'foo',
                  title: 'NO HA INTRODUCIDO CORRECTAMENTE LAS CREDENCIALES',
                  text: 'Pruebe de nuevo o póngase en contacto a través de Whatsapp'
                  });
            }
         }
     }
};
</script>

<style>
.contenedor {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.title{
   text-align: center;
}


.fondo { 
    background: url("../assets/fondo3.png") no-repeat center fixed ;     
    background-size: cover;
}
</style>
