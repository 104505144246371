<template class="fondo">
  <v-container>
     <v-layout wrap justify-space-around>
        <v-flex>
          
          <v-card
            class="carta"
            max-width="434"
            tile
            outlined
            elevation="24"
          >
            <v-img
              height="100%"
              src="../assets/fondo.jpg"
            >
              <v-row
                align="end"
                class="fill-height"
              >
                <v-col
                  align-self="start"
                  class="pa-0"
                  cols="12"
                >
                  <v-avatar
                    class="profile"
                    color="grey"
                    size="164"
                    tile
                  >
                    <v-img src="../assets/player.jpg"></v-img>
                  </v-avatar>
                </v-col>
                <v-col class="py-0">
                  <v-list-item
                    color="rgba(0, 0, 0, .4)"
                    dark
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-h6">
                        {{this.jugador.NOMBRE}}
                      </v-list-item-title>
                      <v-list-item-subtitle>Temporada: {{this.jugador.DISPONIBILIDAD}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-img>
          </v-card>
          
        </v-flex>
      </v-layout>

      <v-card
    class="mx-auto"
    max-width="820"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          INFORMACIÓN
        </div>
        
        <v-list-item-subtitle><v-icon
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>  Documento validado por el equipo ADM (No requiere que realice ninguna acción)</v-list-item-subtitle>
        <v-list-item-subtitle><v-icon
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>  Documento pendiente de validación del equipo ADM (Atentos a la evolución y respuesta del equipo)</v-list-item-subtitle>
        <v-list-item-subtitle><v-icon
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>  No se ha subido ninguna información de este documento (Debe subir dicha documentación en cuanto la tenga disponible)</v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
  </v-card>


  <v-card
    class="mx-auto"
    max-width="820"
    outlined
  >
    <v-list-item three-line>
      <v-list-item-content>
        <div class="text-overline mb-4">
          INFORMACIÓN IMPORTANTE Y DE AYUDA A LAS FAMILIAS
        </div>
        
        <v-list-item-subtitle><a href="documentos/info_faq.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Preguntas Frecuentes</v-list-item-subtitle>
        <v-list-item-subtitle><a href="documentos/info_viaje.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Mi viaje a USA</v-list-item-subtitle>
        <v-list-item-subtitle><a href="documentos/info_codigo.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Código de conducta</v-list-item-subtitle>
      </v-list-item-content>

    </v-list-item>
  </v-card>
    
      <v-card max-width="85%" class="carta" outlined>
        <v-list>
          <v-expansion-panels accordion>
            <!--PASAPORTE-->
            <v-expansion-panel v-if="this.jugador.PASAPORTE!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PASAPORTE==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>PASAPORTE</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.PASAPORTE==1">
                <div class="contenedor3">
                 <v-file-input
                    class="fileinput"
                    v-model="files_pasaporte"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
        
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_pasaporte()"
                  >
                    CONFIRMAR
                  </v-btn>  

                 

                </div>
                <p><a href="documentos/info_pasaporte.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Pasaporte</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--NOTAS 3 ESO-->
            <v-expansion-panel v-if="this.jugador.NOTAS_3_ESO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_3_ESO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 3 ESO (ESPAÑOL & INGLÉS)</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_3_ESO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_3eso"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_3eso()"
                  >
                    CONFIRMAR
                  </v-btn>  


                </div>
                <p><a href="documentos/info_eso.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Notas ESO</p>
                <p>Puede consultar un documento de ejemplo (Notas ESO) clicando sobre el siguiente PDF:</p>

                <a href="documentos/doc_eso.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 4 ESO-->
            <v-expansion-panel v-if="this.jugador.NOTAS_4_ESO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_4_ESO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 4 ESO (ESPAÑOL & INGLÉS)</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_4_ESO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_4eso"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_4eso()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  

                </div>
                <p><a href="documentos/info_eso.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Notas ESO</p>
                <p>Puede consultar un documento de ejemplo (NOTAS ESO) clicando sobre el siguiente PDF:</p>

                <a href="documentos/doc_eso.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 1 BACH-->
            <v-expansion-panel v-if="this.jugador.NOTAS_1_BACH!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_1_BACH==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 1 BACHILLERATO (ESPAÑOL & INGLÉS)</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_1_BACH==1"> 
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_1bach"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_1bach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                 
                </div>
                <p><a href="documentos/info_bachillerato.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Notas Bachillerato</p>
                <p>Puede consultar documentos de ejemplo clicando sobre los siguientes PDFs:</p>
                <p>1 - NOTAS BACHILLERATO ESPAÑOL</p>
                <p>2 - NOTAS BACHILLERATO INGLÉS</p>

                <a href="documentos/doc_es_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

                <a href="documentos/doc_en_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS 2 BACH-->
            <v-expansion-panel v-if="this.jugador.NOTAS_2_BACH!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_2_BACH==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS 2 BACHILLERATO (ESPAÑOL & INGLÉS)</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_2_BACH==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_2bach"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_2bach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                
                </div>
                <p><a href="documentos/info_bachillerato.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Notas Bachillerato</p>
                <p>Puede consultar documentos de ejemplo clicando sobre los siguientes PDFs:</p>
                <p>1 - NOTAS BACHILLERATO ESPAÑOL</p>
                <p>2 - NOTAS BACHILLERATO INGLÉS</p>

                <a href="documentos/doc_es_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

                <a href="documentos/doc_en_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--TITULO BACHILLERATO-->
            <v-expansion-panel v-if="this.jugador.TITULO_BACHILLERATO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TITULO_BACHILLERATO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>TITULO BACHILLERATO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.TITULO_BACHILLERATO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_titulobach"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_titulobach()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  

                </div>
                <p><a href="documentos/info_tit_bach.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Titulo Bachillerato</p>
                <p>Puede consultar documentos de ejemplo clicando sobre los siguientes PDFs:</p>
                <p>1 - TITULO DE BACHILLERATO</p>
                <p>2 - DOCUMENTO FINALIZACIÓN GRADO BACHILLERATO</p>
                <p>1 - DOCUMENTO RANKING BACHILLERATO</p>

                <a href="documentos/doc_tit_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

                <a href="documentos/doc_grad_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

                <a href="documentos/doc_rank_bach.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

               
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--CARTA DEL BANCO-->
            <v-expansion-panel v-if="this.jugador.CARTA_DEL_BANCO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CARTA_DEL_BANCO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>CARTA DEL BANCO (ESPAÑOL & INGLÉS)</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.CARTA_DEL_BANCO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_cartabanco"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_cartabanco()"
                  >
                    CONFIRMAR
                  </v-btn>  

                

                </div>
                <p><a href="documentos/info_carta_banco.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Carta del Banco</p>
                <p>Puede consultar documentos de ejemplo clicando sobre los siguientes PDFs:</p>
                <p>1 - CARTA DEL BANCO (ESPAÑOL)</p>
                <p>2 - CARTA DEL BANCO (INGLÉS)</p>

                <a href="documentos/doc_bank_let.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

                <a href="documentos/doc_bank_es.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>

              
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REGISTRO LIGA-->
            <v-expansion-panel v-if="this.jugador.REGISTRO_LIGA!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_LIGA==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REGISTRO LIGA</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REGISTRO_LIGA==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_registroliga"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    accept="application/pdf"
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_registroliga()"
                  >
                    CONFIRMAR
                  </v-btn>  

                 
                </div>
                <p><a href="documentos/info_ncaa.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Registro NCAA</p>
                <p><a href="documentos/info_naia.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Registro NAIA</p>
                <v-btn
                    color="info"
                    dark
                    large
                    class="boton1"
                    @click="confirmar_naia()"
                  >
                    (VIDEOS) REGISTRO NAIA
                  </v-btn>  

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton1"
                    @click="confirmar_ncaa()"
                  >
                    (VIDEOS) REGISTRO NCAA
                  </v-btn>  
                <div v-if="this.naia_ncaa==1">
                <iframe
                  width="560"
                  height="315"
                  :src="video_naia1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>


                <iframe
                  width="560"
                  height="315"
                  :src="video_naia2"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_naia3"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_naia4"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_naia5"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_naia6"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

              </div>
              <div v-if="this.naia_ncaa==2">
                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>


                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa2"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa3"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa4"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa5"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa6"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa7"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>


                <iframe
                  width="560"
                  height="315"
                  :src="video_ncaa8"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

            </div>
            

                
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REGISTROS UNIVERSIDAD-->
            <v-expansion-panel v-if="this.jugador.REGISTRO_UNIVERSIDAD!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REGISTRO_UNIVERSIDAD==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REGISTRO UNIVERSIDAD</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REGISTRO_UNIVERSIDAD==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_registrouniversidad"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_registrouni()"
                  >
                    CONFIRMAR
                  </v-btn>  

                  

                </div>
                <p><a href="documentos/info_universidad.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Registro Universidad</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--DUOLINGO-->
            <v-expansion-panel v-if="this.jugador.DUOLINGO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.DUOLINGO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>DUOLINGO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.DUOLINGO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_duolingo"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_duolingo()"
                  >
                    CONFIRMAR
                  </v-btn>  

               

                </div>
                <p><a href="documentos/info_toefl_duolingo.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Duolingo</p>
                <p>Puede consultar un documento de ejemplo clicando sobre el siguiente PDF:</p>

                <a href="documentos/doc_duolingo.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--TOEFL-->
            <v-expansion-panel v-if="this.jugador.TOEFL!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.TOEFL==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>TOEFL</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.TOEFL==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_toefl"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_toefl()"
                  >
                    CONFIRMAR
                  </v-btn>  

                

                </div>
                <p><a href="documentos/info_toefl_duolingo.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones TOEFL</p>
                <p>Puede consultar un documento de ejemplo clicando sobre el siguiente PDF:</p>

                <a href="documentos/doc_toefl.pdf" download>
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <!--SAT-->
            <v-expansion-panel v-if="this.jugador.SAT!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.SAT==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SAT==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SAT==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SAT</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.SAT==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_sat"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_sat()"
                  >
                    CONFIRMAR
                  </v-btn>  

               

                </div>
                <p><a href="documentos/info_sat.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones SAT</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--PARTIDOS COMPLETOS 2-->
            <v-expansion-panel v-if="this.jugador.PARTIDOS_COMPLETOS_2!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.PARTIDOS_COMPLETOS_2==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>2 PARTIDOS COMPLETOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.PARTIDOS_COMPLETOS_2==1">
                
                <p><a href="documentos/info_2partidos.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones 2 Partidos Completos</p>
              </v-expansion-panel-content >
            </v-expansion-panel>
             <!--CORTES PARTIDOS-->
            <v-expansion-panel v-if="this.jugador.CORTES_PARTIDOS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.CORTES_PARTIDOS==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>CORTES PARTIDOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.CORTES_PARTIDOS==1">
               
                <p><a href="documentos/info_cortes.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Cortes Partidos</p>
                 

                
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--I20-->
            <v-expansion-panel v-if="this.jugador.I20!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.I20==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.I20==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.I20==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>I20</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.I20==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_i20"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_i20()"
                  >
                    CONFIRMAR
                  </v-btn>  


                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--FORMULARIO ADM-->
            <v-expansion-panel v-if="this.jugador.FORMULARIO_ADM!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.FORMULARIO_ADM==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>FORMULARIO ADM</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.FORMULARIO_ADM==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_formularioadm"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_formularioadm()"
                  >
                    CONFIRMAR
                  </v-btn>  

                 

                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--INCRED-->
            <v-expansion-panel v-if="this.jugador.INCRED!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.INCRED==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>INCRED</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.INCRED==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_incred"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_incred()"
                  >
                    CONFIRMAR
                  </v-btn>  



                </div>
                <p><a href="documentos/info_incred.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones INCRED</p>
               <iframe
                  width="560"
                  height="315"
                  :src="video_incred1"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>


                <iframe
                  width="560"
                  height="315"
                  :src="video_incred2"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_incred3"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_incred4"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_incred5"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_incred6"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>

                <iframe
                  width="560"
                  height="315"
                  :src="video_incred7"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--EVALUACIÓN DE LAS NOTAS-->
            <v-expansion-panel v-if="this.jugador.EVALUACION_DE_LAS_NOTAS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>EVALUACIÓN DE LAS NOTAS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.EVALUACION_DE_LAS_NOTAS==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_evaluacionnotas"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_evaluacionnotas()"
                  >
                    CONFIRMAR
                  </v-btn>  

                
                </div>
                <p><a href="documentos/info_evaluacion.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Evaluación de las Notas</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--NOTAS CARRERA-->
            <v-expansion-panel v-if="this.jugador.NOTAS_CARRERA!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.NOTAS_CARRERA==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>NOTAS CARRERA</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.NOTAS_CARRERA==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_notascarrera"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_notascarrera()"
                  >
                    CONFIRMAR
                  </v-btn>  

                 

                </div>
                <p><a href="documentos/info_carrera.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Notas Carrera</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--REFERENCE LETTER-->
            <v-expansion-panel v-if="this.jugador.REFERENCE_LETTER!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.REFERENCE_LETTER==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>REFERENCE LETTER</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.REFERENCE_LETTER==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_referenceletter"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_referenceletter()"
                  >
                    CONFIRMAR
                  </v-btn>  

                </div>
                <p><a href="documentos/info_reference.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Carta de Recomendación</p>
                <p>Puede consultar un documento de ejemplo clicando sobre el siguiente PDF:</p>
                
                <a href="documentos/doc_reference.pdf" download >
                  <img src="../assets/pdf.png" width="80" height="100">
                </a>
              
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--SEGURO MEDICO-->
            <v-expansion-panel v-if="this.jugador.SEGURO_MEDICO!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.SEGURO_MEDICO==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>SEGURO MÉDICO</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.SEGURO_MEDICO==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_seguromedico"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_seguromedico()"
                  >
                    CONFIRMAR
                  </v-btn>  

        

                </div>
                <p><a href="documentos/info_seguro.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Seguro Médico</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--VUELOS-->
            <v-expansion-panel v-if="this.jugador.VUELOS!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.VUELOS==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>VUELOS</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.VUELOS==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_vuelos"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_vuelos()"
                  >
                    CONFIRMAR
                  </v-btn>  

                </div>
                <p><a href="documentos/info_vuelos.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Vuelos</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
             <!--HOUSING-->
            <v-expansion-panel v-if="this.jugador.HOUSING!=0"
            >
              <v-expansion-panel-header>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==3"
                      color="#00E676"
                    >
                      mdi-check-bold
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==1"
                      color="error"
                    >
                      mdi-exclamation-thick
                    </v-icon>
                    <v-icon
                      v-if="this.jugador.HOUSING==2"
                      color="info"
                    >
                      mdi-block-helper
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>HOUSING</v-list-item-title> 
                  </v-list-item-content>
                </v-list-item>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="this.jugador.HOUSING==1">
                <div class="contenedor3">
                  <v-file-input
                  class="fileinput"
                    v-model="files_housing"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>

                  <v-btn
                    color="info"
                    dark
                    large
                    class="boton"
                    @click="confirmar_housing()"
                  >
                    CONFIRMAR
                  </v-btn> 

                </div>
                <p><a href="documentos/info_housing.pdf" download>
                  <img src="../assets/pdf.png" width="15" height="20">
                </a>Indicaciones Housing</p>
              </v-expansion-panel-content>
            </v-expansion-panel>


          </v-expansion-panels>
        </v-list>
      </v-card>

       
  </v-container>
</template>

<script>
import axios from "axios";

const { GoogleSpreadsheet } = require('google-spreadsheet');
  export default {
    name: 'Info',
    components: {
    },
    data: () => ({
      jugador: "",
      incred1: "https://www.youtube.com/watch?v=e1Tz96tg3e4",
      incred2: "https://www.youtube.com/watch?v=QI5HWPUgp6k",
      incred3: "https://www.youtube.com/watch?v=cB8KII2_yqg",
      incred4: "https://www.youtube.com/watch?v=d0bxjZpH6n0",
      incred5: "https://www.youtube.com/watch?v=bEjwOpeF4os",
      incred6: "https://www.youtube.com/watch?v=mpQKnJ3I6t4",
      incred7: "https://www.youtube.com/watch?v=3UbO1mE3KMU",
      naia1: "https://www.youtube.com/watch?v=SND54qzuTYY",
      naia2: "https://www.youtube.com/watch?v=3qUPfrwA6QI",
      naia3: "https://www.youtube.com/watch?v=bkeqm_PaDw8",
      naia4: "https://www.youtube.com/watch?v=HxSvHHy0m9Q",
      naia5: "https://www.youtube.com/watch?v=8Y3RtsY1cAI",
      naia6: "https://www.youtube.com/watch?v=1MI8MWlntLE",
      ncaa1: "https://www.youtube.com/watch?v=8JpS3emgtYI",
      ncaa2: "https://www.youtube.com/watch?v=7lJ_l9ltjMs",
      ncaa3: "https://www.youtube.com/watch?v=Hq0GHgAXO0Y",
      ncaa4: "https://www.youtube.com/watch?v=59abJFAysEE",
      ncaa5: "https://www.youtube.com/watch?v=bXxu22gsOps",
      ncaa6: "https://www.youtube.com/watch?v=8zw9UHdz1AI",
      ncaa7: "https://www.youtube.com/watch?v=oB44pdB6npA",
      ncaa8: "https://www.youtube.com/watch?v=L_57EK7_QvM",
      naia_ncaa: 0,

    }),
    mounted(){
      this.jugador =this.$route.params.jugador;
      this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
      this.loadURL();
      //this.importar_foto();

    },
    methods: {
      confirmar_naia(){
        this.naia_ncaa=1;
      },
      confirmar_ncaa(){
        this.naia_ncaa=2;
      },
      loadURL () {
        const youtubeEmbedTemplate = 'https://www.youtube.com/embed/'
        const url = this.incred1.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId = undefined !== url[2] ? url[2].split(/[^0-9a-z_/\\-]/i)[0] : url[0]
        const topOfQueue = youtubeEmbedTemplate.concat(YId)
        this.video_incred1 = topOfQueue

        const youtubeEmbedTemplate2 = 'https://www.youtube.com/embed/'
        const url2 = this.incred2.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId2 = undefined !== url2[2] ? url2[2].split(/[^0-9a-z_/\\-]/i)[0] : url2[0]
        const topOfQueue2 = youtubeEmbedTemplate2.concat(YId2)
        this.video_incred2 = topOfQueue2


        const youtubeEmbedTemplate3 = 'https://www.youtube.com/embed/'
        const url3 = this.incred3.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId3 = undefined !== url3[2] ? url3[2].split(/[^0-9a-z_/\\-]/i)[0] : url3[0]
        const topOfQueue3 = youtubeEmbedTemplate3.concat(YId3)
        this.video_incred3 = topOfQueue3


        const youtubeEmbedTemplate4 = 'https://www.youtube.com/embed/'
        const url4 = this.incred4.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId4 = undefined !== url4[2] ? url4[2].split(/[^0-9a-z_/\\-]/i)[0] : url4[0]
        const topOfQueue4 = youtubeEmbedTemplate4.concat(YId4)
        this.video_incred4 = topOfQueue4


        const youtubeEmbedTemplate5 = 'https://www.youtube.com/embed/'
        const url5 = this.incred5.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId5 = undefined !== url5[2] ? url5[2].split(/[^0-9a-z_/\\-]/i)[0] : url5[0]
        const topOfQueue5 = youtubeEmbedTemplate5.concat(YId5)
        this.video_incred5 = topOfQueue5


        const youtubeEmbedTemplate6 = 'https://www.youtube.com/embed/'
        const url6 = this.incred6.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId6 = undefined !== url6[2] ? url6[2].split(/[^0-9a-z_/\\-]/i)[0] : url6[0]
        const topOfQueue6 = youtubeEmbedTemplate6.concat(YId6)
        this.video_incred6 = topOfQueue6

        const youtubeEmbedTemplate7 = 'https://www.youtube.com/embed/'
        const url7 = this.incred7.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId7 = undefined !== url7[2] ? url7[2].split(/[^0-9a-z_/\\-]/i)[0] : url7[0]
        const topOfQueue7 = youtubeEmbedTemplate7.concat(YId7)
        this.video_incred7 = topOfQueue7

        const youtubeEmbedTemplate8 = 'https://www.youtube.com/embed/'
        const url01 = this.naia1.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId01 = undefined !== url01[2] ? url01[2].split(/[^0-9a-z_/\\-]/i)[0] : url01[0]
        const topOfQueue01 = youtubeEmbedTemplate8.concat(YId01)
        this.video_naia1 = topOfQueue01

        const youtubeEmbedTemplate9 = 'https://www.youtube.com/embed/'
        const url02 = this.naia2.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId02 = undefined !== url02[2] ? url02[2].split(/[^0-9a-z_/\\-]/i)[0] : url02[0]
        const topOfQueue02 = youtubeEmbedTemplate9.concat(YId02)
        this.video_naia2 = topOfQueue02

        const youtubeEmbedTemplate10 = 'https://www.youtube.com/embed/'
        const url03 = this.naia3.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId03 = undefined !== url03[2] ? url03[2].split(/[^0-9a-z_/\\-]/i)[0] : url03[0]
        const topOfQueue03 = youtubeEmbedTemplate10.concat(YId03)
        this.video_naia3 = topOfQueue03

        const youtubeEmbedTemplate11 = 'https://www.youtube.com/embed/'
        const url04 = this.naia4.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId04 = undefined !== url04[2] ? url04[2].split(/[^0-9a-z_/\\-]/i)[0] : url04[0]
        const topOfQueue04 = youtubeEmbedTemplate11.concat(YId04)
        this.video_naia4 = topOfQueue04

        const youtubeEmbedTemplate12 = 'https://www.youtube.com/embed/'
        const url05 = this.naia5.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId05 = undefined !== url05[2] ? url05[2].split(/[^0-9a-z_/\\-]/i)[0] : url05[0]
        const topOfQueue05 = youtubeEmbedTemplate12.concat(YId05)
        this.video_naia5 = topOfQueue05

        const youtubeEmbedTemplate13 = 'https://www.youtube.com/embed/'
        const url06 = this.naia6.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId06 = undefined !== url06[2] ? url06[2].split(/[^0-9a-z_/\\-]/i)[0] : url06[0]
        const topOfQueue06 = youtubeEmbedTemplate13.concat(YId06)
        this.video_naia6 = topOfQueue06

        const youtubeEmbedTemplate14 = 'https://www.youtube.com/embed/'
        const url11 = this.ncaa1.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId11 = undefined !== url11[2] ? url11[2].split(/[^0-9a-z_/\\-]/i)[0] : url11[0]
        const topOfQueue11 = youtubeEmbedTemplate14.concat(YId11)
        this.video_ncaa1 = topOfQueue11

        const youtubeEmbedTemplate15 = 'https://www.youtube.com/embed/'
        const url12 = this.ncaa2.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId12 = undefined !== url12[2] ? url12[2].split(/[^0-9a-z_/\\-]/i)[0] : url12[0]
        const topOfQueue12 = youtubeEmbedTemplate15.concat(YId12)
        this.video_ncaa2 = topOfQueue12

        const youtubeEmbedTemplate16 = 'https://www.youtube.com/embed/'
        const url13 = this.ncaa3.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId13 = undefined !== url13[2] ? url13[2].split(/[^0-9a-z_/\\-]/i)[0] : url13[0]
        const topOfQueue13 = youtubeEmbedTemplate16.concat(YId13)
        this.video_ncaa3 = topOfQueue13

        const youtubeEmbedTemplate17 = 'https://www.youtube.com/embed/'
        const url14 = this.ncaa4.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId14 = undefined !== url14[2] ? url14[2].split(/[^0-9a-z_/\\-]/i)[0] : url14[0]
        const topOfQueue14 = youtubeEmbedTemplate17.concat(YId14)
        this.video_ncaa4 = topOfQueue14

        const youtubeEmbedTemplate18 = 'https://www.youtube.com/embed/'
        const url15 = this.ncaa5.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId15 = undefined !== url15[2] ? url15[2].split(/[^0-9a-z_/\\-]/i)[0] : url15[0]
        const topOfQueue15 = youtubeEmbedTemplate18.concat(YId15)
        this.video_ncaa5 = topOfQueue15

        const youtubeEmbedTemplate19 = 'https://www.youtube.com/embed/'
        const url16 = this.ncaa6.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId16 = undefined !== url16[2] ? url16[2].split(/[^0-9a-z_/\\-]/i)[0] : url16[0]
        const topOfQueue16 = youtubeEmbedTemplate19.concat(YId16)
        this.video_ncaa6 = topOfQueue16

        const youtubeEmbedTemplate20 = 'https://www.youtube.com/embed/'
        const url17 = this.ncaa7.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId17 = undefined !== url17[2] ? url17[2].split(/[^0-9a-z_/\\-]/i)[0] : url17[0]
        const topOfQueue17 = youtubeEmbedTemplate20.concat(YId17)
        this.video_ncaa7 = topOfQueue17

        const youtubeEmbedTemplate21 = 'https://www.youtube.com/embed/'
        const url18 = this.ncaa8.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/)
        const YId18 = undefined !== url18[2] ? url18[2].split(/[^0-9a-z_/\\-]/i)[0] : url18[0]
        const topOfQueue18 = youtubeEmbedTemplate21.concat(YId18)
        this.video_ncaa8 = topOfQueue18
        
        
      },
      async importar_foto(){
        var res = await axios.get('https://t4t.infor.uva.es/get_foto?name='+this.jugador.NOMBRE);
        console.log(res);
        console.info('Useful information');      },
      async confirmar_pasaporte(){

        //cambiar doc que se ajuste con drive
        var doc1 = "PASAPORTE"

        //cambiar el for
        for (let x = 0; x < this.files_pasaporte.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_pasaporte[x],this.files_pasaporte[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }

        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json');

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); 

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PASAPORTE=2;
              await rows[i].save();
              this.jugador.PASAPORTE=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });

      
      },
      async confirmar_3eso(){
        //cambiar doc que se ajuste con drive
        var doc1 = "NOTAS 3 ESO"

        //cambiar el for
        for (let x = 0; x < this.files_3eso.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_3eso[x],this.files_3eso[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }

        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_3_ESO=2;
              await rows[i].save();
              this.jugador.NOTAS_3_ESO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_4eso(){
        //cambiar doc que se ajuste con drive
        var doc1 = "NOTAS 4 ESO"

        //cambiar el for
        for (let x = 0; x < this.files_4eso.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_4eso[x],this.files_4eso[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }

        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_4_ESO=2;
              await rows[i].save();
              this.jugador.NOTAS_4_ESO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_1bach(){
        //cambiar doc que se ajuste con drive
        var doc1 = "NOTAS 1 BACHILLERATO"

        //cambiar el for
        for (let x = 0; x < this.files_1bach.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_1bach[x],this.files_1bach[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }

        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_1_BACH=2;
              await rows[i].save();
              this.jugador.NOTAS_1_BACH=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_2bach(){
        //cambiar doc que se ajuste con drive
        var doc1 = "NOTAS 2 BACHILLERATO"

        //cambiar el for
        for (let x = 0; x < this.files_2bach.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_2bach[x],this.files_2bach[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_2_BACH=2;
              await rows[i].save();
              this.jugador.NOTAS_2_BACH=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_titulobach(){
        //cambiar doc que se ajuste con drive
        var doc1 = "TITULO BACHILLERATO"

        //cambiar el for
        for (let x = 0; x < this.files_titulobach.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_titulobach[x],this.files_titulobach[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }

        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TITULO_BACHILLERATO=2;
              await rows[i].save();
              this.jugador.TITULO_BACHILLERATO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_cartabanco(){
         //cambiar doc que se ajuste con drive
        var doc1 = "CARTA DEL BANCO"

        //cambiar el for
        for (let x = 0; x < this.files_cartabanco.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_cartabanco[x],this.files_cartabanco[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CARTA_DEL_BANCO=2;
              await rows[i].save();
              this.jugador.CARTA_DEL_BANCO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_registroliga(){

         //cambiar doc que se ajuste con drive
        var doc1 = "REGISTRO LIGA"

        //cambiar el for
        for (let x = 0; x < this.files_registroliga.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_registroliga[x],this.files_registroliga[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_LIGA=2;
              await rows[i].save();
              this.jugador.REGISTRO_LIGA=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_registrouni(){
          //cambiar doc que se ajuste con drive
        var doc1 = "REGISTRO UNIVERSIDAD"

        //cambiar el for
        for (let x = 0; x < this.files_registrouniversidad.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_registrouniversidad[x],this.files_registrouniversidad[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REGISTRO_UNIVERSIDAD=2;
              await rows[i].save();
              this.jugador.REGISTRO_UNIVERSIDAD=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_duolingo(){
          //cambiar doc que se ajuste con drive
        var doc1 = "DUOLINGO"

        //cambiar el for
        for (let x = 0; x < this.files_duolingo.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_duolingo[x],this.files_duolingo[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].DUOLINGO=2;
              await rows[i].save();
              this.jugador.DUOLINGO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_toefl(){
          //cambiar doc que se ajuste con drive
        var doc1 = "TOEFL"

        //cambiar el for
        for (let x = 0; x < this.files_toefl.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_toefl[x],this.files_toefl[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].TOEFL=2;
              await rows[i].save();
              this.jugador.TOEFL=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_sat(){
          //cambiar doc que se ajuste con drive
        var doc1 = "SAT"

        //cambiar el for
        for (let x = 0; x < this.files_sat.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_sat[x],this.files_sat[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SAT=2;
              await rows[i].save();
              this.jugador.SAT=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_2partidos(){
           //cambiar doc que se ajuste con drive
        var doc1 = "2 PARTIDOS COMPLETOS"

        //cambiar el for
        for (let x = 0; x < this.files_2partidos.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_2partidos[x],this.files_2partidos[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
         const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].PARTIDOS_COMPLETOS_2=2;
              await rows[i].save();
              this.jugador.PARTIDOS_COMPLETOS_2=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_cortespartidos(){
            //cambiar doc que se ajuste con drive
        var doc1 = "CORTES PARTIDOS"

        //cambiar el for
        for (let x = 0; x < this.files_cortespartidos.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_cortespartidos[x],this.files_cortespartidos[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].CORTES_PARTIDOS=2;
              await rows[i].save();
              this.jugador.CORTES_PARTIDOS=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_i20(){
             //cambiar doc que se ajuste con drive
        var doc1 = "I20"

        //cambiar el for
        for (let x = 0; x < this.files_i20.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_i20[x],this.files_i20[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].I20=2;
              await rows[i].save();
              this.jugador.I20=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_formularioadm(){
              //cambiar doc que se ajuste con drive
        var doc1 = "FORMULARIO ADM"

        //cambiar el for
        for (let x = 0; x < this.files_formularioadm.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_formularioadm[x],this.files_formularioadm[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].FORMULARIO_ADM=2;
              await rows[i].save();
              this.jugador.FORMULARIO_ADM=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_incred(){
              //cambiar doc que se ajuste con drive
        var doc1 = "INCRED"

        //cambiar el for
        for (let x = 0; x < this.files_incred.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_incred[x],this.files_incred[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].INCRED=2;
              await rows[i].save();
              this.jugador.INCRED=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_evaluacionnotas(){

               //cambiar doc que se ajuste con drive
        var doc1 = "EVALUACIÓN DE LAS NOTAS"

        //cambiar el for
        for (let x = 0; x < this.files_evaluacionnotas.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_evaluacionnotas[x],this.files_evaluacionnotas[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].EVALUACION_DE_LAS_NOTAS=2;
              await rows[i].save();
              this.jugador.EVALUACION_DE_LAS_NOTAS=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_notascarrera(){
        
               //cambiar doc que se ajuste con drive
        var doc1 = "NOTAS CARRERA"

        //cambiar el for
        for (let x = 0; x < this.files_notascarrera.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_notascarrera[x],this.files_notascarrera[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].NOTAS_CARRERA=2;
              await rows[i].save();
              this.jugador.NOTAS_CARRERA=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_referenceletter(){
                //cambiar doc que se ajuste con drive
        var doc1 = "REFERENCE LETTER"

        //cambiar el for
        for (let x = 0; x < this.files_referenceletter.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_referenceletter[x],this.files_referenceletter[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].REFERENCE_LETTER=2;
              await rows[i].save();
              this.jugador.REFERENCE_LETTER=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_seguromedico(){
                 //cambiar doc que se ajuste con drive
        var doc1 = "SEGURO MÉDICO"

        //cambiar el for
        for (let x = 0; x < this.files_seguromedico.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_seguromedico[x],this.files_seguromedico[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].SEGURO_MEDICO=2;
              await rows[i].save();
              this.jugador.SEGURO_MEDICO=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_vuelos(){
                  //cambiar doc que se ajuste con drive
        var doc1 = "VUELOS"

        //cambiar el for
        for (let x = 0; x < this.files_vuelos.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_vuelos[x],this.files_vuelos[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].VUELOS=2;
              await rows[i].save();
              this.jugador.VUELOS=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
         this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      async confirmar_housing(){
                   //cambiar doc que se ajuste con drive
        var doc1 = "HOUSING"

        //cambiar el for
        for (let x = 0; x < this.files_housing.length; x++){
          var formData = new FormData();
          formData.append('file',this.files_housing[x],this.files_housing[x].name);
          try{
          let res = await axios.post('https://t4t.infor.uva.es/upload?temp='+this.jugador.DISPONIBILIDAD+"&name="+this.jugador.NOMBRE+"&doc="+doc1, formData);
          console.log(res);
          console.info('Useful information');          
          }catch(err){
            console.info('Useful information');          }
        }
        const doc = new GoogleSpreadsheet('1C4_ce6UCrVAZnibKsED6TuvHUqgyiSSMYaY2uJ8JRA0');
        const creds = require('../../public/service_account.json'); // the file saved above

        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows(); // can pass in { limit, offset }

        for (let i = 0; i < rows.length; i++){
            if(this.jugador.NOMBRE==rows[i].NOMBRE){
              rows[i].HOUSING=2;
              await rows[i].save();
              this.jugador.HOUSING=2;
              this.jugador.PORCENTAJE=this.calculaporcentaje(this.jugador);
              this.$forceUpdate();
            }
        }
        this.$notify({
              group: 'foo',
              title: 'INFORMACIÓN SUBIDA CON ÉXITO',
              text: 'Se ha subido correctamente la documentación de '+this.jugador.NOMBRE+". Actualmente el equipo ADM revisará la documentación."
            });
      },
      calculaporcentaje(jugador){
            var cuenta = 0;
            var correcto = 0;
            if (jugador.PASAPORTE!=0){
                cuenta=cuenta+1;
                if (jugador.PASAPORTE==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_3_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_3_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_4_ESO!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_4_ESO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_1_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_1_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_2_BACH!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_2_BACH==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TITULO_BACHILLERATO!=0){
                cuenta=cuenta+1;
                if (jugador.TITULO_BACHILLERATO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.CARTA_DEL_BANCO!=0){
                cuenta=cuenta+1;
                if (jugador.CARTA_DEL_BANCO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_LIGA!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_LIGA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REGISTRO_UNIVERSIDAD!=0){
                cuenta=cuenta+1;
                if (jugador.REGISTRO_UNIVERSIDAD==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.DUOLINGO!=0){
                cuenta=cuenta+1;
                if (jugador.DUOLINGO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.TOEFL!=0){
                cuenta=cuenta+1;
                if (jugador.TOEFL==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SAT!=0){
                cuenta=cuenta+1;
                if (jugador.SAT==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.PARTIDOS_COMPLETOS_2!=0){
                cuenta=cuenta+1;
                if (jugador.PARTIDOS_COMPLETOS_2==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.I20!=0){
                cuenta=cuenta+1;
                if (jugador.I20==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.FORMULARIO_ADM!=0){
                cuenta=cuenta+1;
                if (jugador.FORMULARIO_ADM==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.INCRED!=0){
                cuenta=cuenta+1;
                if (jugador.INCRED==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.EVALUACION_DE_LAS_NOTAS!=0){
                cuenta=cuenta+1;
                if (jugador.EVALUACION_DE_LAS_NOTAS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.NOTAS_CARRERA!=0){
                cuenta=cuenta+1;
                if (jugador.NOTAS_CARRERA==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.REFERENCE_LETTER!=0){
                cuenta=cuenta+1;
                if (jugador.REFERENCE_LETTER==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.SEGURO_MEDICO!=0){
                cuenta=cuenta+1;
                if (jugador.SEGURO_MEDICO==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.VUELOS!=0){
                cuenta=cuenta+1;
                if (jugador.VUELOS==3){
                    correcto=correcto+1;
                }
            }
            if (jugador.HOUSING!=0){
                cuenta=cuenta+1;
                if (jugador.HOUSING==3){
                    correcto=correcto+1;
                }
            }

            if (correcto==0){
                return 0;
            }else{
            return parseInt((correcto/cuenta)*100);
            }
        }
    }
  }
</script>
<style scoped lang="scss">
.contenedor1{
  display: flex;
}


.izquierda{
  flex: 1;
}

.derecha{
  flex: 1;
}

.card{
  float: right;
  margin-right: 10px;
}

.card1{
  margin-left: 10px;
}


.boton{
  margin-bottom: 10px;
}

.selector{
  margin-bottom: 15px;
}

.item{
  margin-top: 15px;
}

.linear{
  display: inline-block;
  padding: 10px;
}

.linear1{
  display: inline-block;
}

.flex {
  flex-grow: 0;
}


.flex-empty {
  height: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  
  div {
    width: 150px;
  }
}

.contenedor3{
  display: flex;
}


.boton1{
    margin-left: 2%;
}

.carta{
  margin-bottom: 5%;
}

.fondo { 
    background: url("../assets/fondo3.png") no-repeat center fixed ;       
    background-size: cover;
  
}
.fileinput{
  margin-right: 10%;
}

.carta{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

a{
  margin: 5px;
}

.boton1{
  margin: 5px;
}

.mx-auto{
  margin: 5px;
}

</style>